import { useMutation } from '@tanstack/react-query';

import client from './client';
import { BaseResp } from './response/BaseResp';
import SignInResp from './response/SignInResp';

export type Account = {
  email: string;
  passwd: string;
};

const signIn = (account: Account): Promise<SignInResp> => client.post('/v1/account/login', { ...account });
export const useSignInMutation = () => useMutation(signIn);

export const signOut = () => client.delete('/v1/account/logout');

const checkPassword = (password: string): Promise<BaseResp> => client.post('/v1/account/pass-check', { password });
export const useCheckPasswordMutation = () => useMutation(checkPassword);


