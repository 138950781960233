import { Outlet } from 'react-router-dom';

function Popup() {
  return (
    <section>
      <div className={'pop-wrap'}>
        <Outlet />
        <div className={'pop-bottom input-large'}>
          <button
            type={'button'}
            className={'btn-type-02'}
            onClick={() => {
              window.close();
            }}
          >
            {'닫기'}
          </button>
        </div>
      </div>
    </section>
  );
}

export default Popup;
