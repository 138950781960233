import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { signOut } from 'api/sessionApi';
import { settingState } from 'components/recoil/setting/atom';
import { userInfoState } from 'components/recoil/user/atom';

function Header() {
  const userInfo = useRecoilValue(userInfoState);
  const resetUserInfo = useResetRecoilState(userInfoState);
  const resetSetting = useResetRecoilState(settingState);
  const navigate = useNavigate();

  const handleChangeInfo = () => {
    navigate('/mypage');
  };

  const handleSignOut = async () => {
    await signOut();
    resetUserInfo();
    resetSetting();
    sessionStorage.clear();

    navigate('/sign-in');
  };

  return (
    <header className={'header'}>
      <h1 className={'logo-title'}>
        <i className={'samsung-logo-text'} />
        <span>{'재고 관리 시스템'}</span>
      </h1>
      <section className={'header-right-section'}>
        <div className={'header-info'}>
          <label htmlFor={'user-name'}>{'슈퍼관리자 :'}</label>
          <span className={'user-name'} id={'user-name'}>
            {userInfo.name}
          </span>
          <button
            type={'button'}
            className={'p-right btn-type-01'}
            onClick={handleChangeInfo}
          >
            {'정보변경'}
          </button>
        </div>
        <div className={'header-info'}>
          <label htmlFor={'last-login'}>{'최근로그인 :'}</label>
          <time id={'last-login'}>
            <span>{userInfo.latestDt}</span>
          </time>
          <button
            type={'button'}
            className={'p-right btn-type-02'}
            onClick={handleSignOut}
          >
            {'로그아웃'}
          </button>
        </div>
      </section>
    </header>
  );
}

export default Header;
