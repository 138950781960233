import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

type UserInfo = {
  name: string;
  latestDt: string;
  isAuthenticated: boolean;
  isAdminAuthenticated: boolean;
}

export const userInfoPersist = recoilPersist({
  key: 'userInfo',
  storage: sessionStorage,
  converter: JSON
})

export const userInfoState = atom<UserInfo>({
  key: 'userInfoState',
  default: {
    name: '',
    latestDt: '',
    isAuthenticated: false,
    isAdminAuthenticated: false
  },
  effects_UNSTABLE: [userInfoPersist.persistAtom]
})