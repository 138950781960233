import { ReactNode, useMemo, useState } from 'react';

import {
  ComponentType,
  ModalContextProps,
  ModalProps,
  ModalsDispatchContext,
  ModalsStateContext,
} from './ModalContext';

export default function ModalContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [openedModals, setOpenedModals] = useState<ModalContextProps[]>([]);
  const open = (Component: ComponentType, props: ModalProps) => {
    setOpenedModals((modals) => [...modals, { Component, props }]);
  };
  const close = (Component: ComponentType) => {
    setOpenedModals((modals) =>
      modals.filter((modal) => modal.Component !== Component),
    );
  };
  const dispatch = useMemo(() => ({ open, close }), []);

  return (
    <ModalsStateContext.Provider value={openedModals}>
      <ModalsDispatchContext.Provider value={dispatch}>
        {children}
        {/* <Modals /> */}
      </ModalsDispatchContext.Provider>
    </ModalsStateContext.Provider>
  );
}
