import { SidebarItemType } from 'types/sidebar';

import SidebarNavDepthItem from './SidebarNavDepthItem';
import SidebarNavItem from './SidebarNavItem';

type SidebarNavSectionProps = {
  items: SidebarItemType[];
};

function SidebarNavList({ items }: SidebarNavSectionProps) {
  return (
    <ul>
      {items &&
        items.map((item) =>
          item?.children ? (
            <SidebarNavDepthItem
              item={item}
              key={item.href}
              className={'depth-02'}
            />
          ) : (
            <SidebarNavItem
              key={item.href}
              item={item}
              className={'depth-01'}
            />
          ),
        )}
    </ul>
  );
}

export default SidebarNavList;
