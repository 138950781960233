import { createContext, FunctionComponent } from 'react';

export type ModalProps = {
  onSubmit: () => void;
  onClose: () => void;
};

export type ComponentType = FunctionComponent<ModalProps>;

export type ModalContextProps = {
  Component: ComponentType;
  props: ModalProps;
};

type ModalsDispatchContextProps = {
  open: (Component: ComponentType, props: ModalProps) => void;
  close: (Component: ComponentType) => void;
};

export const ModalsDispatchContext = createContext<ModalsDispatchContextProps>({
  open: () => {},
  close: () => {},
});
export const ModalsStateContext = createContext<ModalContextProps[]>([]);
