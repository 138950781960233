import { settingState } from 'components/recoil/setting/atom';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { YN_TYPE } from 'types/enumType';
import SidebarNavList from './SidebarNavList';
import dashboardItemList from './dashboardItemList';

function SideBar() {
  const [setting, setSetting] = useRecoilState(settingState);
  const adminIndex = dashboardItemList.findIndex(
    (item) => item.href === '/management',
  );
  const filteredDashboardItemList = _.cloneDeep(dashboardItemList);
  filteredDashboardItemList[adminIndex].children = dashboardItemList[
    adminIndex
  ].children?.filter((subItem) => {
    if (subItem.href === '/install' && setting.installYn === YN_TYPE.N) {
      return null;
    }
    if (subItem.href === '/category' && setting.cateYn === YN_TYPE.N) {
      return null;
    }
    if (subItem.href === '/lot' && setting.warehouseYn === YN_TYPE.N) {
      return null;
    }
    return subItem;
  });

  return (
    <nav className={'left-menu'} data-simplebar>
      <SidebarNavList items={filteredDashboardItemList} />
    </nav>
  );
}

export default SideBar;
