/* eslint-disable react/jsx-no-useless-fragment */
import { ReactElement, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { userInfoState } from 'components/recoil/user/atom';

interface AuthGuardProps {
  children: ReactNode;
}

const getNavigateAfterCheckLogin = (
  url: string,
  isAuthenticated: boolean,
): ReactElement | null => {
  if (isAuthenticated) {
    if (url === '/sign-in' || url === '/sign-up') {
      return <Navigate to={'/dashboard'} replace={false} />;
    }
  } else {
    if (!(url === '/sign-in' || url === '/sign-up')) {
      return <Navigate to={'/sign-in'} />;
    }

    return null;
  }

  return null;
};

const AuthGuard = ({ children }: AuthGuardProps) => {
  const userInfo = useRecoilValue(userInfoState);
  const location = useLocation();

  const url = location.pathname;

  const navigateElement = getNavigateAfterCheckLogin(
    url,
    userInfo.isAuthenticated,
  );
  if (navigateElement) {
    return navigateElement;
  }

  return <>{children}</>;
};

export default AuthGuard;
