import { lazy, useState } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import Default from 'pages/Default';

import PopupDefault from 'pages/Popup';
import InOutPopup from 'components/popup/InOutPopup';
import DetailPopup from 'components/popup/DetailPopup';
import RegistPopup from 'components/popup/RegistPopup';
import SignIn from '../pages/SignIn';
import AuthGuard from './AuthGuard';
import ProtectedRoute from './ProtectedRoute';
import Popup from '../components/popup/Popup';

const Dashboard = lazy(() => import('pages/Dashboard'));
const Stock = lazy(() => import('pages/Stock'));
const Goods = lazy(() => import('pages/Goods'));
const InstallStock = lazy(() => import('pages/InstallStock'));
const Admin = lazy(() => import('pages/Admin'));
const Install = lazy(() => import('pages/Install'));
const Category = lazy(() => import('pages/Category'));
const Lot = lazy(() => import('pages/Lot'));
const Warehouse = lazy(() => import('pages/Warehouse'));
const UserLog = lazy(() => import('pages/UserLog'));
const Mypage = lazy(() => import('pages/Mypage'));
const AuthConfirmPassword = lazy(() => import('pages/AuthConfirmPassword'));
const SystemSetting = lazy(() => import('pages/SystemSetting'));
const Detail = lazy(() => import('pages/GoodsDetail'));

const routes = createBrowserRouter([
  {
    path: '/sign-in',
    element: (
      <AuthGuard>
        <SignIn />
      </AuthGuard>
    ),
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <Default />
      </AuthGuard>
    ),
    children: [
      {
        path: '/dashboard',
        element: <Dashboard />,
      },
      { path: '/auth-confirm', element: <AuthConfirmPassword /> },
      {
        path: '/mypage',
        element: (
          <ProtectedRoute>
            <Mypage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/stock',
        element: <Stock />,
      },
      {
        path: '/goods',
        element: <Goods />,
      },
      {
        path: '/detail/:goodsId',
        element: <Detail />,
      },
      {
        path: '/install-stock',
        element: <InstallStock />,
      },
      {
        path: '/admin',
        element: (
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        ),
      },
      {
        path: '/install',
        element: (
          <ProtectedRoute>
            <Install />
          </ProtectedRoute>
        ),
      },
      {
        path: '/category',
        element: (
          <ProtectedRoute>
            <Category />
          </ProtectedRoute>
        ),
      },
      {
        path: '/lot',
        element: (
          <ProtectedRoute>
            <Lot />
          </ProtectedRoute>
        ),
      },
      {
        path: '/warehouse',
        element: (
          <ProtectedRoute>
            <Warehouse />
          </ProtectedRoute>
        ),
      },
      {
        path: '/user-log',
        element: (
          <ProtectedRoute>
            <UserLog />
          </ProtectedRoute>
        ),
      },
      {
        path: '/setting',
        element: (
          <ProtectedRoute>
            <SystemSetting />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: '/popup',
    element: <PopupDefault />,
    children: [
      {
        path: '/popup/regist',
        element: <RegistPopup />,
      },
      {
        path: '/popup/popup',
        element: <Popup />,
        children: [
          // {
          //   path: '/popup/popup/in-out',
          //   element: <InOutPopup />,
          // },
          {
            path: '/popup/popup/detail/',
            element: <DetailPopup />,
          },
        ],
      },
    ],
  },
]);

export default routes;
