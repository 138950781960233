import { SidebarItemType } from 'types/sidebar';

const dashboardItemList: SidebarItemType[] = [
  {
    href: '/dashboard',
    title: 'Home',
    name: '홈',
  },
  {
    href: '/stock',
    title: 'Stock',
    name: '재고 관리',
  },
  {
    href: '/goods',
    title: 'Goods',
    name: '상품 관리',
  },
  {
    href: '/install-stock',
    title: '설비 입출고 관리',
    name: '설비 입출고 관리',
  },
  {
    href: '/management',
    title: 'Management',
    name: '관리자 관리',
    children: [
      {
        href: '/admin',
        title: 'Admin',
        name: '관리자 관리',
      },
      {
        href: '/install',
        title: '설비 관리',
        name: '설비 관리',
      },
      {
        href: '/category',
        title: 'Category',
        name: '카테고리 관리',
      },
      {
        href: '/lot',
        title: 'Lot',
        name: '보관장소 관리',
      },
      {
        href: '/warehouse',
        title: 'Warehouse',
        name: '창고 관리',
      },
      {
        href: '/user-log',
        title: 'User Log',
        name: '관리자 접속기록',
      },
      {
        href: '/setting',
        title: 'Setting',
        name: '시스템 설정',
      },
    ],
  },
];

export default dashboardItemList;
