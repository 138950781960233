import axios, { AxiosError } from 'axios';
import qs from 'qs';

const client = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
  paramsSerializer: (param) => qs.stringify(param),
  transformRequest: (data) => qs.stringify(data),
  withCredentials: true,
});

client.interceptors.response.use(
  (response) => response.data,
  (error: Error | AxiosError) => {
    console.log(error);
    if (!axios.isAxiosError(error)) {
      return null;
    }

    if (error.response?.status === 401) {
      window.location.replace('/sign-in');
      sessionStorage.clear();
    }

    return Promise.reject(error);
  },
);

export default client;
