import { MouseEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { userInfoState } from 'components/recoil/user/atom';
import { SidebarItemType } from 'types/sidebar';

import SidebarNavItem from './SidebarNavItem';

type SidebarDepthNavItemProps = {
  item: SidebarItemType;
  className: string;
};

function SidebarNavDepthItem({ item, className }: SidebarDepthNavItemProps) {
  const userInfo = useRecoilValue(userInfoState);
  const navigate = useNavigate();
  const [onOpen, setOnOpen] = useState(false);
  const toggleOpen = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (onOpen) {
      return;
    }

    if (!userInfo?.isAdminAuthenticated) {
      navigate('/admin');
    } else {
      setOnOpen(userInfo?.isAdminAuthenticated);
    }
  };

  useEffect(() => {
    setOnOpen(userInfo?.isAdminAuthenticated);
  }, [userInfo.isAdminAuthenticated]);

  return (
    <li className={'depth-01'} key={item.href}>
      <a
        href={'/'}
        className={`depth-more ${onOpen ? 'on' : ''}`}
        onClick={toggleOpen}
      >
        {item.name}
      </a>
      <ul className={`depth-02-wrap ${onOpen ? 'active' : ''}`}>
        {item.children &&
          item.children.map((childItem) => (
            <SidebarNavItem
              key={childItem.href}
              className={className}
              item={childItem}
            />
          ))}
      </ul>
    </li>
  );
}

export default SidebarNavDepthItem;
