import Setting from "model/Setting";
import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { YN_TYPE } from "types/enumType";

export const settingPersist = recoilPersist({
  key: 'settingState',
  storage: sessionStorage
})

export const settingState = atom<Setting>({
  key: 'settingState',
  default: {
    installYn: YN_TYPE.N,
    cateYn: YN_TYPE.N,
    warehouseYn: YN_TYPE.N
  },
  effects_UNSTABLE: [settingPersist.persistAtom]
})