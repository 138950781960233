export const enum PAGE_TYPE {
  ALL_PREV,
  PREV,
  NEXT,
  ALL_NEXT,
}

export const enum YN_TYPE {
  Y = 'Y',
  N = 'N',
}

export const enum STOCK_TYPE {
  S = 'S',
  U = 'U',
}

export const enum USER_LOG_TYPE {
  L = 'L',
  P = 'P',
  S = 'S',
  O = 'O',
}
