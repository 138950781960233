import { ReactNode, Suspense } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { userInfoState } from 'components/recoil/user/atom';
import { isAdminPage } from 'util/commonUtil';

type ProtectedRouteProps = {
  children: ReactNode;
};

export default function ProtectedRoute({ children }: ProtectedRouteProps) {
  const userInfo = useRecoilValue(userInfoState);
  const { state, pathname } = useLocation();

  if (isAdminPage(pathname)) {
    if (!userInfo?.isAdminAuthenticated) {
      return <Navigate to={'/auth-confirm'} state={{ pathname }} />;
    }
    return <Suspense>{children}</Suspense>;
  }
  if (!state?.isAuthenticated) {
    return <Navigate to={'/auth-confirm'} state={{ pathname }} />;
  }

  return <Suspense>{children}</Suspense>;
}
