import Header from 'layouts/Header';
import SideBar from 'layouts/sidebar/Sidebar';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

const Default = () => (
  <>
    <Header />
    <main className={'main'}>
      <SideBar />
      <Suspense>
        <Outlet />
      </Suspense>
    </main>
  </>
);

export default Default;
