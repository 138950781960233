import SignInComponent from 'components/SignIn';
import React from 'react';

function SignIn() {
  return (
    <section className={'login-section'}>
      <h1 className={'logo-title'}>
        <i className={'samsung-logo'} />
        <span>{'재고 관리 시스템'}</span>
      </h1>
      <SignInComponent />
    </section>
  );
}

export default SignIn;
