import React from 'react';
import { Outlet } from 'react-router-dom';

function Popup() {
  return (
    <>
      <div className={'pop-header'}>
        <h2>
          <span>{'6라인 장비 부품 (Q320-077061)'}</span>
        </h2>
      </div>
      <div className={'pop-body'} data-simplebar>
        <div className={'img-wrap device-info'}>
          <div className={'img-box'}>{}</div>
          <table className={'tbl-type-05 input-small'}>
            <caption>{'장비부품테이블'}</caption>
            <colgroup>
              <col width={'20%'} />
              <col />
              <col width={'20%'} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>{'카테고리'}</th>
                <td>{}</td>
                <th>{'상품코드'}</th>
                <td>{}</td>
              </tr>
              <tr>
                <th>{'보관위치'}</th>
                <td>{}</td>
                <th>{'보관위치코드'}</th>
                <td>{}</td>
              </tr>
              <tr>
                <th>{'현재재고'}</th>
                <td>{}</td>
                <th>{'적정재고'}</th>
                <td>{}</td>
              </tr>
              <tr>
                <th>{'등록일시'}</th>
                <td>{}</td>
                <th>{'최근 입/출고 일시'}</th>
                <td>{}</td>
              </tr>
              <tr>
                <th>{'상품설명'}</th>
                <td colSpan={3}>
                  <p className={'local-text-01'}>{}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Outlet />
      </div>
    </>
  );
}

export default Popup;
